import { utilityMutations } from "./utility";

export const formProcessingState = {
    isValid: null,
    isFormProcessing: false,
    formActionIsAdd: true,
}

export const formProcessingGetters = {
    getFormId: state => {
        return state.formId;
    },
    getFormIsValid: state => {
        return state.isValid;
    },
    getFormActionIsAdd: state => {
        return state.formActionIsAdd;
    }
}

export const formProcessingMutations = {
    ...utilityMutations
}

export const formProcessingActions = {
    setFormProcessing({ commit }, value){
        commit('SET', ['isFormProcessing', value]);
    },
    setFormIsAdd({ commit }, value){
        commit('SET', ['formActionIsAdd', value]);
    },
    setFormIsValid({ commit }, value){
        commit('SET', ['isValid', value]);
    },
    validateForm({ commit }, formId){
        const form = document.querySelector(formId);

        if (!form.checkValidity()) {
            commit('SET', ['isValid', false]);
        } else {
            commit('SET', ['isValid', true]);
        }
        form.classList.add('was-validated');
    },
    resetAllFormProcessingValues({ commit }){
        commit('SET', ['isValid', null]);
        commit('SET', ['isFormProcessing', false]);
    },
    clearFormValidation({ getters }){
        //removes validation from form inputs
        let formId = getters.getFormId;
        let form   = document.querySelector(formId);
        if(form.classList.contains('was-validated')){
            form.classList.remove('was-validated');
        }

        //get read of error messages if there previously was any
        let formErrorsDiv  = document.querySelector('.form-errors');
        formErrorsDiv.innerHTML = '';
        //gets rid of the error class on the error message div
        if(formErrorsDiv.classList.contains('alert')){
            formErrorsDiv.classList.remove('alert', 'alert-danger');
        }

        //go through each input and remove error class and error messages
        let inputs = document.querySelectorAll('.is-invalid');
        inputs.forEach(input => {
            input.classList.remove('is-invalid');
            input.nextElementSibling.innerHTML = input.dataset.invalidFeedback;
        });

    },
}