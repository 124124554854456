import store from "@v/Modules/Blog/Core/Site/store";

const inits = [
    { name: 'site_recent_blogs', el: '#blog-recent'},
    { name: 'site_blog_post', el: 'div#site-blog-post-page'},
]

inits.forEach(i => {
    if (document.querySelector(i.el)) {
        window[`${i.name}_vue_instance`] = new Vue({
            store,
            el: i.el
        });
    }
});