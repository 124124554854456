import Vuex from 'vuex';

//modules
import blog from './modules/blog';
import comment from './modules/comment';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        blog,
        comment
    }
});