import { utilityMutations } from '@v/Core/Global/Store/utility';
import _ from 'lodash';
import EloquentSSFP from '@j/core/global/EloquentSSFP';

export const state = {
    blogs: {},
    pageType: 'posts',
    activeRecord: {
        id: '',
        name: ''
    },
    categories: {},
    recent: [],
    archives: {},
    featured: [],
    hcaptchaSiteKey: null,
    activeFilterTab: sessionStorage.getItem("activeFilterTab") ? sessionStorage.getItem("activeFilterTab") : 'categories'
}

export const getters = {
    firstFeaturedBlog: state => {
        if (state.featured.length) return state.featured[0];
    },
    getBlogPosts: state => {
       return state.blogs;
    },
    getCurrentFilterName: state => {
        let val = state.activeRecord.title || state.activeRecord.name;
        return val || null;
    }
}

export const mutations = {
    ...utilityMutations
}

export const actions = {
    initSSFP({ commit }, [pageType, record]) {
        let endpoint;

        if((pageType == 'category' || pageType == 'tag')){
            endpoint = `${pageType}/${record.id}`;
        } else if(pageType == 'archive'){
            endpoint = `${pageType}/${record.permalink}`;
        } else {
            endpoint = 'posts';
        }

        //set/get all blog listings
        commit('SET', ['blogs', new EloquentSSFP(`/blog-list/${endpoint}`, {
            searchableData: ['title', 'content', 'author_name'],
            itemsPerPage: 10,
            activeSorts: ['scheduled_at'],
            sortTypes: ['date'],
            sortDirs: ['desc']
        })]);
    },
    setData({ commit }, [variable, data]){
        commit('SET', [variable, data]);
    },
    setFilterTab({ state, commit }, value) {
        let result = value;
        if (state.activeFilterTab == value) result = null;
        commit('SET', ['activeFilterTab', result])
        sessionStorage.setItem('activeFilterTab', result);
    }
}

export default { namespaced: true, state, getters, mutations, actions };