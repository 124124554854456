import { utilityMutations } from  '@v/Core/Global/Store/utility';
import { formProcessingActions, formProcessingGetters, formProcessingMutations, formProcessingState } from  '@v/Core/Global/Store/form-processing';

export const state = {
    ...formProcessingState,
    formId: '#add-comment-form',
    activeRecord :{
        name: '',
        comment: '',
        email: '',
        post_id: '',
        status: 'Pending',
        is_site: true,
        hcaptchaToken: null,
        hcaptchaEkey: null,
        hcaptchaIsValid: false,
    },
    successModalVisible: false,
    message: null,
    response: null
}

export const getters = {
    ...formProcessingGetters
}

export const mutations = {
    ...formProcessingMutations,
    ...utilityMutations,
    SET_POST_ID(state, id) {
        state.activeRecord.post_id = id;
    }
}

export const actions = {
    ...formProcessingActions,
    submitForm({ commit, dispatch, getters, state }, blogId) {
        commit('SET_POST_ID', blogId);

        let formId        = getters.getFormId;
        let formData      = state.activeRecord;
        let formActionUrl = '/blog/comment/store'

        //check if the form is valid
        dispatch('validateForm', formId);

        if (getters.getFormIsValid) {
            //set the form processing variable
            dispatch('setFormProcessing', true);

            axios.post(formActionUrl, formData)
            .then(response => {
                //If successful
                if (response.status == 201 || response.status == 200) {
                    //Show success notification
                    commit('SET', ['message', response.data.message]);
                    commit('SET', ['response', 'Success']);
                    commit('SET', ['successModalVisible', true]);
                    //reset all form processing variables
                    dispatch('resetAllFormProcessingValues');
                    dispatch('resetComment');
                }
            }).catch(error => {
                //set the form processing variable
                dispatch('setFormProcessing', false);
                if (error.request) {
                    if (error.request.status == 422) {
                        commit('SET', ['response', 'Error']);
                        commit('SET', ['message', "We're sorry an error has occured. Please Try again later."]);
                        commit('SET', ['successModalVisible', true]);
                    } else {
                        commit('SET', ['message', "We're sorry an error has occured. Please Try again later."]);
                        commit('SET', ['successModalVisible', true]);
                    }
                    console.log(error);
                }
            });
        }
    },
    resetComment({ commit, dispatch }) {
        //clear out active record variable
        commit('SET', ['activeRecord', {
                name: '',
                comment: '',
                email: '',
                post_id: '',
                status: 'Pending',
                is_site: true
            }
        ]);

        dispatch('clearFormValidation');
    },
    closeModal({commit, dispatch}) {
        commit('SET', ['successModalVisible', false]);
        dispatch('resetComment');
    }
}

export default { namespaced: true, state, getters, mutations, actions };